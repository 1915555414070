<template>
  <div>
    <!-- pic07A1264164BCC29647B729ABBA035565.jpg -->
    <img class="rounded img-large" src="">
    <p>
      After a successful landing for Pod 5 in Berlin, the signal for Pod 6 has centered on England. While Queen Elizabeth is yet to release a statement, thousands of British LEGO fans are moving ahead with preparations for the arrival of the sixth mysterious pod.
    </p>
    <p>
      Remember, if you are fortunate enough to find the pod or get to the landing site on the day of the pod's arrival, you need to write down the pod ID. This can be used at the Bradford Rant website to unlock the pod data and begin the important work of decoding the message!
    </p>
    <p>
      To see how you can find a pod and to help reveal the mystery, visit <a href="http://www.bradfordrant.org/">www.Bradfordrant.org</a>.
    </p>
  </div>
</template>
